<template>
  <v-app id="inspire" v-if="$store.state.user">
    <v-navigation-drawer v-model="drawer" :mobile-breakpoint="768" app :right="$vuetify.rtl">
      <div class="text-center">
        <v-img class="pa-4" src="mountains.jpg" height="100"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)">
          <v-avatar color="secondary" class="white--text" size="40px">
            <v-icon dark>mdi-account</v-icon>
          </v-avatar>
          <div class="white--text text-subtitle-1 font-weight-bold mt-1"
            style="text-shadow: 1px 1px 5px #000, -1px -1px 5px #000" v-if="user">
            {{ userData.FullName }}
          </div>
        </v-img>
      </div>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="changePasswordDlg = true">
          <v-list-item-icon>
            <v-icon>mdi-form-textbox-password</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>שינוי סיסמה</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>יציאה מהמערכת</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2 text-center">
          <img src="/k.png" style="opacity: 1; width: 181px; position: relative; left: 0px; top:10px;" />
          <br>
          <small>by&nbsp;&nbsp;&nbsp;<b>T A C O</b>&nbsp;&nbsp;&nbsp;</small>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="white" prominent height="100">
      <v-container class="header-container pa-0">
        <v-row>
          <!-- <v-app-bar-nav-icon @click="drawer = !drawer" style="align-self: center"></v-app-bar-nav-icon> -->
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-center mt-5">
            <img class="logo-img" src="/dalkalgas-logo.svg"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <router-view @forceChangePassword="forceChangePasswordHandler"></router-view>
        <snackbar-cmp />
      </div>
    </v-main>

    <v-dialog v-model="changePasswordDlg" max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6" :style="{ fontFamily: '\'Varela Round\', sans-serif !important' }">
            שינוי סיסמה
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-container>
            <v-col cols="12" sm="12">
              <v-text-field label="סיסמה נוכחית" type="password" dense outlined v-model="currentPassword"
                hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field label="סיסמה חדשה" type="password" dense outlined v-model="newPassword1"
                hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field label="סיסמה חדשה פעם נוספת" type="password" dense outlined v-model="newPassword2"
                hide-details></v-text-field>
            </v-col>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" class="message" :class="{ success: success, error: !success }">
            {{ message }}
          </v-col>
          <v-btn color="primary" text @click="close" v-if="!success">
            ביטול
          </v-btn>
          <v-btn color="primary" @click="save" v-if="!success"> אישור </v-btn>
          <v-btn color="primary" @click="close" v-if="success"> סגור </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="noAccessDlg" max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6" :style="{ fontFamily: '\'Varela Round\', sans-serif !important' }">
            גישה מוגבלת
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-container>

            <p>
              <br>
              הגישה למודול זה אינה מורשית עבור המשתמש הנוכחי
            </p>

          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" class="message" :class="{ success: success, error: !success }">
            {{ message }}
          </v-col>
          <v-btn color="primary" @click="noAccessDlg = false"> סגור </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import { mapState } from "vuex";

export default {
  data: () => ({
    noAccessDlg: false,
    drawer: null,
    userData: null,
    changePasswordDlg: false,
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
    message: "",
    success: false,
    items: [
      //{ title: "דיווח צריכת חלפים", icon: "mdi-format-list-checks", to: "/" },
      // { title: "הגדרות", icon: "mdi-account-cog", to: "/settings" },
      // { title: "אודות", icon: "mdi-information-outline", to: "/about" },
    ],
    specialNav: {},
  }),
  components: {
    "snackbar-cmp": require("@/components/Shared/SnackbarCmp.vue").default,
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    if (this.user) {
      var token = this.user.accessToken;
      var decoded = jwt_decode(token);

      this.userData = decoded;

      if (this.userData.Permissions && this.userData.Permissions.indexOf('11,') > -1) {
        this.items.unshift({ title: "שיחות לקוח", icon: "mdi-forum-outline", to: "/chat" });
      }

    }

  },
  methods: {
    forceChangePasswordHandler() {
      this.changePasswordDlg = true;
    },
    close() {
      this.currentPassword = "";
      this.newPassword1 = "";
      this.newPassword2 = "";
      this.changePasswordDlg = false;
      this.message = "";
      this.success = false;
    },
    save() {
      this.success = false;
      if (!this.currentPassword || !this.newPassword1 || !this.newPassword2) {
        this.message = "פרטים חסרים";
      } else if (this.newPassword1 !== this.newPassword2) {
        this.message = "הסיסמאות אינן זהות";
      } else if (this.newPassword1.length < 5) {
        this.message = "הסיסמה שבחרת קצרה מדי";
      } else {
        axios
          .post(`auth/password`, {
            currentPassword: this.currentPassword,
            newPassword1: this.newPassword1,
            newPassword2: this.newPassword2,
          })
          .then(() => {
            this.changePasswordDlg = false;
            this.$store.commit("showSnackbar", "!הסיסמה שונתה בהצלחה");
            //this.$toast("");
            this.message = "";
            this.success = true;
          })
          .catch(() => {
            this.message = "תקלה טכנית";
            this.success = false;
          })
          .finally(() => {
            console.log('finally')
          });
      }

      this.currentPassword = "";
      this.newPassword1 = "";
      this.newPassword2 = "";
      //this.changePasswordDlg = false;
    },

    logout() {
      this.$store.commit("clearUserData");
      //window.localStorage.removeItem("user");
    },
  },
};
</script>

<style>
body {
  font-family: "Varela Round", sans-serif;
}

.v-application,
.login {
  font-family: "Varela Round", sans-serif;
}

.v-main {
  background-color: #f7fcff;
}

.message {
  position: absolute;
  display: inline-block;
  width: 100%;
  padding: 0 50px;
  background-color: transparent !important;
  right: 0;
  -webkit-animation: fadein-data-v-1797a707 5s normal forwards;
  animation: fadein-data-v-1797a707 5s normal forwards;
  text-align: right;
}

#inspire .message.error {
  color: #ac2221;
  background-color: transparent !important;
}

.message.success {
  color: #0e98c2;
  background-color: transparent !important;
}

@media screen and (max-width: 600px) {
  ::v-deep .v-toolbar .boxed-container>.d-flex {
    justify-content: space-between;
  }
}

.logo-img {
  max-width: 230px;
}
</style>