<template>
  <component
    :is="this.$route.name === 'Login' ? 'layout-blank' : (this.$route.name === 'CleanChat' ? 'layout-chat': 'layout-content')">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from "@/layouts/LayoutBlank.vue";
import LayoutContent from "@/layouts/LayoutContent.vue";
import LayoutChat from "./layouts/LayoutChat.vue";

export default {
  name: "App",

  data: () => ({}),
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutChat,
  },
};
</script>

<style>
html {
  overflow-y: auto;
}

body {
  font-family: "Varela Round", sans-serif;
}

.theme--light.v-application .text--primary {
  color: #555 !important;
}

.v-application,
.login {
  font-family: "Varela Round", sans-serif;
}
</style>