<template>
  <v-app id="inspire" style="max-height: 100vh">
    <!-- <v-app-bar app color="white" prominent height="100">
      <v-container class="header-container pa-0">
        <v-row>
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-center mt-5">
            <img class="logo-img" src="/dalkalgas-logo.svg" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-app-bar> -->

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <router-view></router-view>
        <snackbar-cmp />
      </div>
    </v-main>

    <!-- <span style="position: absolute; z-index: 5555; top: 50px; left: 50px">
      {{ height }}
    </span> -->
  </v-app>
</template>

<script>
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { mapState } from 'vuex';

export default {
  data: () => ({
    noAccessDlg: false,
    drawer: null,
    userData: null,
    changePasswordDlg: false,
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
    message: '',
    success: false,
    items: [
      //{ title: "דיווח צריכת חלפים", icon: "mdi-format-list-checks", to: "/" },
      // { title: "הגדרות", icon: "mdi-account-cog", to: "/settings" },
      // { title: "אודות", icon: "mdi-information-outline", to: "/about" },
    ],
    specialNav: {},
    height: 0,
  }),
  components: {
    'snackbar-cmp': require('@/components/Shared/SnackbarCmp.vue').default,
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    window.addEventListener('resize', () => {
      let el = document.getElementById('inspire');
      this.height = el.clientHeight;
    });
    if (this.user) {
      var token = this.user.accessToken;
      var decoded = jwt_decode(token);

      this.userData = decoded;

      if (
        this.userData.Permissions &&
        this.userData.Permissions.indexOf('11,') > -1
      ) {
        this.items.unshift({
          title: 'שיחות לקוח',
          icon: 'mdi-forum-outline',
          to: '/chat',
        });
      }
    }
  },
  methods: {
    forceChangePasswordHandler() {
      this.changePasswordDlg = true;
    },
    close() {
      this.currentPassword = '';
      this.newPassword1 = '';
      this.newPassword2 = '';
      this.changePasswordDlg = false;
      this.message = '';
      this.success = false;
    },
    save() {
      this.success = false;
      if (!this.currentPassword || !this.newPassword1 || !this.newPassword2) {
        this.message = 'פרטים חסרים';
      } else if (this.newPassword1 !== this.newPassword2) {
        this.message = 'הסיסמאות אינן זהות';
      } else if (this.newPassword1.length < 5) {
        this.message = 'הסיסמה שבחרת קצרה מדי';
      } else {
        axios
          .post(`auth/password`, {
            currentPassword: this.currentPassword,
            newPassword1: this.newPassword1,
            newPassword2: this.newPassword2,
          })
          .then(() => {
            this.changePasswordDlg = false;
            this.$store.commit('showSnackbar', '!הסיסמה שונתה בהצלחה');
            //this.$toast("");
            this.message = '';
            this.success = true;
          })
          .catch(() => {
            this.message = 'תקלה טכנית';
            this.success = false;
          })
          .finally(() => {
            console.log('finally');
          });
      }

      this.currentPassword = '';
      this.newPassword1 = '';
      this.newPassword2 = '';
      //this.changePasswordDlg = false;
    },

    logout() {
      this.$store.commit('clearUserData');
      //window.localStorage.removeItem("user");
    },
  },
};
</script>

<style>
body {
  font-family: 'Varela Round', sans-serif;
}

.v-application,
.login {
  font-family: 'Varela Round', sans-serif;
}

.v-main {
  background-color: #f7fcff;
}

.message {
  position: absolute;
  display: inline-block;
  width: 100%;
  padding: 0 50px;
  background-color: transparent !important;
  right: 0;
  -webkit-animation: fadein-data-v-1797a707 5s normal forwards;
  animation: fadein-data-v-1797a707 5s normal forwards;
  text-align: right;
}

#inspire .message.error {
  color: #ac2221;
  background-color: transparent !important;
}

.message.success {
  color: #0e98c2;
  background-color: transparent !important;
}

@media screen and (max-width: 600px) {
  ::v-deep .v-toolbar .boxed-container > .d-flex {
    justify-content: space-between;
  }
}

.logo-img {
  max-width: 230px;
}
</style>
